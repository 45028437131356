// this is to be moved in the base theme if it plays nicely throughout the project 
.header-usp,
footer {
    .Image {
        &:not(.Logo) {
            padding: 0;
            height: auto;
            display: block;
            width: auto!important;

            .Image-Image {
                position: relative;
                height: auto !important;
                object-fit: unset;
                display: block;
                width: auto!important;
            }
        }
    }
}