/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.KlavyioForm {
    input[type=email] {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
        padding-left: 10px;
        padding-right: 10px;

        &:focus {
            border: 1px solid var(--input-border-color);
        }

    }
    button {
        display: block;
        width: 100%;
        border: 1px solid rgb(255, 255, 255);
        padding: 11px;
        font-size: 16px;
        font-weight: 400;
        color: rgb(255, 255, 255);
        text-transform: capitalize;
    }
}