.CmsPage {

    h1 {
        &.CmsPage {
            &-Heading {
                font-size: var(--h2-font-size);

                @include mobile {
                    font-size: var(--h3-font-size);
                }

            }
        }
    }

    &-Heading {
        @include desktop {
            margin: 0 auto;
            max-width: 810px;
        }

        .HomePage & {
            display: none;
        }
    }

    &-Wrapper {
        .HomePage & {
            margin-top: 0;
        }
    }

    &-Content {
        @include desktop {
            margin: 0 auto;
            max-width: 810px;
        }

        h1 {
            font-size: var(--h2-font-size);
        }

        h2 {
            font-size: var(--h3-font-size);
        }

        h3 {
            font-size: var(--h4-font-size);
        }

        h4 {
            font-size: var(--h5-font-size);
        }

        h5 {
            font-size: var(--h6-font-size);
        }

        h6 {
            font-size: var(--h6-font-size);
        }
    }
}

.HomePage {
    .CmsPage {
        &-Heading, &-Content {
            @include desktop {
                margin: 0;
                max-width: unset;


                h2 {
                    font-size: var(--h3-font-size);
                }

                h3 {
                    font-size: var(--h3-font-size);
                }
            }
        }
    }
}
