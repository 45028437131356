/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Footer {
    --footer-background-color: #0e0e0e; 
    .Image {
        padding: 0;
        height: auto;
        display: block;
        width: 350px !important;

        .Image-Image {
            position: relative;
            height: auto !important;
            width: 100%;
            max-width: 100%;
            object-fit: unset;
            display: block;
        }
    }

    &-Content {
        background-color: var(--footer-background-color);
    }
    &-CopyrightContentWrapper {
        background-color: var(--footer-background-color);
    }

    &-Columns {

        @include mobile {
            padding-left: 20px;
            padding-right: 20px;
        }

        flex-direction: column;

        .CmsBlock-Wrapper {
            @include desktop {
                display: flex;
            }
            
            &_type_ {
            @include tablet {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }}
        }

    }

    &-Column {
        padding-right: 0!important;
        margin-left: 2rem;
        margin-right: 2rem;

        .icon-footer {
            filter: invert(1);
            max-width: 22px;
            
            @include mobile {
                margin: 0 auto;
                display: block;

            }

            @include tablet {
                margin-left: -40px;
                margin-bottom: -42px;
            }

            @include desktop {
                margin-left: -40px;
                margin-bottom: -42px;
            }
        }

        @include tablet {
            max-width: 30%;
            width: 30%;
            display: inline-block;
            margin-left: 10%;
            margin-right: 0;
        }

        @include desktop {
            max-width: 25%;
            width: 25%;
        }

        &:not(:last-of-type) {
            max-width: unset;
        }

        .CmsBlock-Wrapper {
            display: flex;
        }

        ul {
            li {
                padding-left: 0;
                &:before {
                    display: none;
                }
            }
        }

        &_isNewsletter {
            text-align: center;
            margin-bottom: 8rem;
            width: 100%;
            max-width: 100%;
            padding-left: 0!important;

            @include tablet {
                margin-left: 0%;

            }

            @include before-desktop {
                margin-bottom: 50px!important;
            }

            .NewsletterSubscription {
                display: flex;
                justify-content: center;
                align-items: center;

                .FieldForm {       
                    &-Fields {     
                        max-width: 100%;

                        @include desktop {
                            width: 300px;
                        }

                        input {
                            &::placeholder {
                                color: var(--var-body-content-color);
                            }
                        }

                    } 

                    &-Button {
                        background-color: #504e4e!important;
                        border-color: #504e4e!important;
                    }

                }

                .FieldForm-Button {
                    background-color: var(--secondary-base-color);
                }

            }
        }

    }

    &-ColumnTitle {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 900;
    }

    &-ColumnContent {
        * {
            color:#c1bdbb; 
            font-size: 1.3rem;
            text-transform: uppercase;
        }

        li {
            margin-bottom: 1rem;
        }

        a {
            &:hover {
                text-decoration: none;
                color: #fff;
            }
        }
    }

    .KlavyioForm {
        @include mobile {
            min-width: 300px;
        }
    }

}

