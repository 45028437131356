/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductActions {

    &-Section {
        &_type_name {
            @include desktop {
                padding-top: 0;
            }
        }

        &_type_sku {
            @include desktop {
                padding-left: 0;
            }
        }

        &_type_short {
            @include desktop {
                padding: 0 0 2rem 0;
                order: 25;
            }
        }

        &_type_customizable_options {
            @include desktop {
                padding: 0;
            }
        }

    }

    &-Attributes {
        @include desktop {
            padding: 0;
        }
        .ExpandableContent {
            @include desktop {
                margin: 0;
            }
        }
    }

    &-AddToCartWrapper {
        @include desktop {
            display: flex;
            margin-bottom: 3rem;
        }

        .ProductWishlistButton {
            @include desktop {
                order: 3;
            }
        }

    }

    &-Title {
        font-size: 3.2rem;
        font-weight: 600;
        color: var(--primary-base-color);
        text-transform: uppercase;
        line-height: normal;
        font-size: 2.8rem;
        padding-bottom: 2.1rem;
    }

    &-Qty {
        @include desktop {
            order: 1;
        }
    }

    &-AddToCart {
        @include desktop {
            order: 2;
            flex-grow: 1;
        }
    }

    &-Schema {
        order: 10;
    }

    &-PriceWrapper {
        margin-bottom: 1.8rem;
        @include desktop {
            margin-top: 1.8rem;
        }
    }

    &-Price {
        @include desktop {
            font-size: 3.2rem;
        }
    }

    &-ConfigurablePriceBadge {
        color: var(--product-actions-option-border-color);
    }

    &-Stock::after {
        content: '•';
        margin: 0 5px;
    }
    
}
