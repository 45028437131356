.header-usp {
    height: var(--header-top-menu-height);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__usp-container {
        padding: 0 .6rem;
        display: flex;
        align-items: center;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }

        &__link {
            display: block;
            margin-left: 1rem;
            color: grey;
            
            &__title {
                font-weight: bold;
            }
        }

    }

}