/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    @include desktop {
        --header-menu-height: 50px;
    }
}

.Header {

    @include desktop {
        position: relative;
    }

    &_isHiddenOnMobile {
        @include before-desktop {
            pointer-events: unset;
            opacity: 1;
        }
    }

    &-Wrapper {
        @include desktop {
            margin-bottom: calc(var(--header-total-height) + 0px);
        }

        @include desktop {
            margin-bottom: 0;
        }

        &_name_default {
            @include mobile {
                margin-bottom: var(--header-height);
            }
        }
    }

    &_name_default {
        @include mobile {
            height: var(--header-height);

            .Header-Nav {
                display: flex;
            }
        }
    }

    &-TopMenu {
        @include desktop {
            padding-right: 1.2rem;
            display: block;
            padding-top: 0;
        }
    }

    &-Button {
        &_type_minicart {
            padding-right: 0;
        }
    }

    &-MinicartButtonWrapper {
        padding-right: 0;
    }

    &-HrSearch {
        position: relative;
        width: 100%;
        margin-top: 1rem;
        display: none;

        &_mobileActive {
            display: block;
        }

        @include desktop {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 0;
            width: auto;
            display: block;
        }

        &-Input {
            border-radius: 20px;
            padding: 1rem 5.5rem 1rem 3rem;
            background-color: var(--secondary-base-color);
            width: 100%;
            @include desktop {
                min-width: 500px;
                height: 40px;
            }

            &:active,
            &:focus {
                border: 1px solid var(--input-border-color);
            }

        }

        &-Icon {
            width: 30px;
            height: 30px;
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(icons/icon-search.svg);
            background-size: contain;
        }
    }

    &-Title {
        @include mobile {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: auto!important;
        }
    }

}

.ClerkSearch {
    &-InputWrapper {
        margin-left: auto;
        margin-right: auto;
    }

    &_mobileActive {
        display: block;
    }

    @include desktop {

    }

    &-Input {
        border-radius: 20px;
        padding: 1rem 5.5rem 1rem 3rem;
        background-color: var(--secondary-base-color);
        width: 100%;
        @include desktop {
            min-width: 400px;
            height: 40px;
        }

        &:active,
        &:focus {
            border: 1px solid var(--input-border-color);
        }


        &-Icon {
            width: 30px;
            height: 30px;
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(/../../hoeks-base/src/component/Header/icons/icon-search.svg);
            background-size: contain;
        }
    }
}


.ProductCard {
    padding-left: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;

    &::before {
        content: none;
    }

    &:last-child {
        margin-bottom: .72rem;

        @include mobile {
            margin-bottom: .84rem;
        }
    }

    &-Content {
        padding: 10px 0 15px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        &_layout_list {
            justify-content: space-between;
            margin-left: 20px;
            width: 100%;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-right: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-bottom: 0;
                    }

                    .ProductCompareButton-Button {
                        height: 42px;
                        width: 42px;
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                }

                &-WishListButton {
                    height: 42px;
                    width: 42px;
                }
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        margin: 5px 0;
        line-height: var(--product-card-name-line-height);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1.26rem;

        @include after-mobile {
            font-size: 1.4rem;
            line-height: 1.3;
        }
    }

    &-Brand {
        font-weight: 300;
        opacity: .5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 1.1rem;

        @include after-mobile {
            font-size: 1.2rem;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-bottom: 120%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        bottom: 0;
        margin: auto;
        top: 0;
    }

    &-FigureReview {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &-Reviews {
        --product-rating-size: 1.6rem;
        --product-rating-background: #ffff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 105px;
        height: 25px;
        position: absolute;
        background-color: var(--product-card-reviews-background);
        border-radius: 1rem;
        letter-spacing: normal;

        &_layout_grid {
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }

        @include mobile {
            --product-rating-size: 1.4rem;

            width: 100%;
            border-radius: 0;
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin: 5px 7px 0 0;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 5px 7px 0 0;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
    }

    &-Price {
        width: 100%;
        @include after-mobile {
            font-size: 2rem;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 1rem;
        margin-bottom: 0;
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &-ProductActions {
        position: absolute;
        top: 3%;
        right: 3%;
        display: flex;
        flex-direction: column;
    }

    &-WishListButton {
        width: 35px;
        height: 35px;
        background-color: var(--product-card-wishlist-button-background);
        border: none;
        transition: all .2s;
        opacity: .7;

        .ProductWishlistButton-Heart {
            --wishlist-heart-size: 15px;

            transform: scale(.7) rotate(-45deg);
            background-color: var(--product-card-wishlist-button-color);

            @include before-desktop {
                transform: scale(.6) rotate(-45deg);
            }
        }

        ::before,
        ::after {
            background-color: var(--product-card-wishlist-button-color);
        }

        &:hover {
            @include after-mobile {
                opacity: 1;
                transition: all .2s;
            }

            .ProductWishlistButton-Heart {
                @include after-mobile {
                    background-color: var(--primary-base-color);
                }
            }

            ::before,
            ::after {
                @include after-mobile {
                    background-color: var(--primary-base-color);
                }
            }
        }

        &:not([disabled]):hover,
        &:not([disabled]):focus {
            background-color: var(--product-card-wishlist-button-background);
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    .ProductWishlistButton {
        margin: 0 0 1.08rem 0;

        &-Button_isInWishlist {
            background-color: var(--product-card-wishlist-button-background);
            opacity: 1;

            .ProductWishlistButton-Heart {
                background-color: var(--primary-base-color);
            }

            ::before,
            ::after {
                background-color: var(--primary-base-color);
            }
        }
    }

    .ProductCompareButton {
        margin: 0 0 1.08rem 0;

        &-Button {
            width: 35px;
            height: 35px;
            border: none;
        }

        &-Icon {
            height: 23px;
            margin: auto;

            &::before {
                width: 5px;
                left: -5px;
            }

            &::after {
                width: 5px;
            }
        }
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }
}
.ProductCard {

    &-Link {
        flex-grow: 1;
    }

    &-Name {
        font-weight: 600;
        padding-bottom: 1rem;
        overflow: visible;
        white-space: normal;
        text-overflow: clip;
    }

    &-PriceBadge {
        color: #101010;
        color: var(--price-discount-color);
    }

    &-ConfigurableOptions {
        margin-top: 5px;
    }

    &-String {
        border-color: var(--primary-base-color);
        color: #fff;
        background: var(--primary-base-color);
    }

    &-DiscountSplat {
        background-color: yellow;
        padding: 1rem 2rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;

        @include mobile {
            font-size: 1.5rem;
        }

        &_noDiscount {
            background-color: transparent;
            height: 39px;
        }

    }

    &-PriceBadge {
        // display: none;
        margin-right: 12px;
        opacity: .5;
        text-align: center;
        line-height: 10px;
        color: #c7f0ff;
    }

    &-PriceWrapper {
        flex-direction: row;
        background-color: var(--primary-base-color);
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        flex-grow: 0;
        min-height: 50px;
    }

    &-Price {
        display: flex;
        align-items: center;
        width: auto;

        @include mobile {
            font-size: 1.5rem;
        }

        ins {
            display: block;
        }

        del {
            display: block;
            margin-top: 2px;
        }
    }


}
