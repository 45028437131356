/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.CategoryMenuItem {
    &-Item {
        text-transform: uppercase;
        padding: .6rem 0; 

        @include mobile {
            padding: var(--option-padding);
        }
    }

    &-Link {
        font-size: 1.2rem;
        color: var(--option-text-color);
        display: inline-block;
        line-height: 2.2rem;
        transition: all ease .1s;

        &:hover {
            text-decoration: none;
        }

        @include mobile {
            font-size: 1.47rem;
            height: 3.2rem;
        }

        &_active {
            cursor: default;
            pointer-events: none;        
            text-decoration: none;
            color: #0a0a0a;
            font-weight: 700;

            &_undefined {
                color: #0a0a0a;
                font-weight: 400;
            }
        }

        &_isParent {
            &::after {
                margin-left: 1rem;
                display: inline-block;
                content: '';
                border-width: 0 0 1px 1px;
                border-style: solid;
                height: 6px;
                width: 6px;
                right: 0;
                transform: translateY(-50%) rotate(-45deg);
                transition: all ease .1s;
                position: relative;
            }
        }

        &_active,
        &_activeParent {
            color: #0a0a0a;

            &::after {
                transform: rotate(135deg);
                top: 1px;
            }
        }

        &:hover {
            color: #0a0a0a;
        }
    }

    &-SubCategories {
        margin-left: 1.4rem;
        margin-top: 1rem;

        &::before {
            content: '';
            position: absolute;
            left: -1.4rem;
            height: 100%;
            top: 0;
            width: 1px;
            border-left: 1px solid rgb(204, 204, 204)
        }

        &:empty {
            display: none;
        }

        .CategoryMenuItem {
            &-Item {
                padding: 0;
            }
        }

    }
}
