:root{
    --option-text-color: #0b445a;
}

.CategoryMenuItem {
    
    &-Link {
        color: var(--option-text-color);
        line-height: 1.7rem;
    }
}
