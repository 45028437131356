/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../hoeks-pwa/src/style/abstract/variables';
@import '../../../../hoeks-pwa/src/style/abstract/media';
@import '../../../../hoeks-pwa/src/style/abstract/button';
@import '../../../../hoeks-pwa/src/style/abstract/loader';
@import '../../../../hoeks-pwa/src/style/abstract/icons';
@import '../../../../hoeks-pwa/src/style/abstract/parts';

.ProductGallery {
    height: auto;
    margin-bottom: 5rem;
    &-Slider {
        &_isZoomInCursor {
            .Image {
                &-Image {
                    width: auto!important;
                    height: auto!important;
                    max-width: 100%!important;
                    max-height: 100%!important;
                    display: block;
                    position: relative;
                    margin: 0 auto;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    &-Additional {
        &:empty {
            display: none;
        }
    }
}

.ImageZoomPopup-PopupContent {
    .ProductGallery-SliderImage .Image-Image {
        width: auto !important;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
    }

    .ProductGallery {
        height: 100%;
        margin-bottom: 0;
    }
}