/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../hoeks-pwa/src/style/abstract/variables';
@import '../../../../hoeks-pwa/src/style/abstract/media';
@import '../../../../hoeks-pwa/src/style/abstract/button';
@import '../../../../hoeks-pwa/src/style/abstract/loader';
@import '../../../../hoeks-pwa/src/style/abstract/icons';
@import '../../../../hoeks-pwa/src/style/abstract/parts';

.ProductActions {
    &-Section {
        &_type{
            &_name {
                @include mobile {
                    order: -1;
                    display: block;
                    padding: 1.4rem;
                }
            }
            &_short {
                @include mobile {
                    padding-left: 1.4rem;
                    padding-right: 1.4rem;
                }
            }
        }
    }
}
