.footer-copyright-wrapper {
    background-color: var(--footer-background-color);
    border-top: 1px solid #1b1b18;

    .footer-copyright {
        padding: var(--cms-spacing-x1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #c1bdbb;

        @include desktop {
            flex-direction: row-reverse;
            justify-content: space-between;
            padding-left: 70px;
            padding-right: 70px;
            max-width: var(--content-wrapper-width);
            margin-left: auto;
            margin-right: auto;
        }

        .footer-copy-right {
            &-right {
                width: 450px;
                max-width: 100%;
                
                @include desktop {
                    margin-left: 2rem;
                }

            }
        }

        .footer-copy-right-left {
            text-align: right;

            @include desktop {
                margin-right: 2rem;
            }

            p {
                font-size: 1.2rem;
            }
        }


        .Image {
            padding: 0;
            height: auto;
            display: block;
            width: auto!important;

            .Image-Image {
                position: relative;
                height: auto !important;
                width: auto!important;
                object-fit: unset;
                display: block;
            }
        }

        p {
            @include desktop {
                margin-bottom: 0;
            }
        }
    }
}