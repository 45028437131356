/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductConfigurableAttributes {
    &-DropDownList {
        padding-left: 1rem;
    }

    &-SwatchList {
        padding-left: 1rem;
        display: block;
        text-align: left;
    }

    &.ProductActions-Attributes {
        padding: 0;
        margin-bottom: 2rem;

        .ExpandableContent {
            margin: 0;
            &-Content {
                margin: 0;
                margin-top: 1rem;
            }
            &-SubHeading {
                display: none;
            }
        }

        .ExpandableContent-Button {
            min-height: auto;
        }

        .ExpandableContent-SubHeading {
            padding-left: 0;
        }

        .ProductConfigurableAttributes-SwatchList {
            padding-left: 0;
        }

    }

}

.CategoryFilterOverlay {
    .ProductConfigurableAttributes {
        &-Expandable {
            border-bottom: 1px solid #0f4c81;

            &:last-child {
                border-bottom: 4px double #0f4c81;
            }
        }
    }
}
