:root {
    --p-font-size: 1.6rem;
    --p-font-weight: 400;
    --h1-font-size: 6.4rem;
    --h2-font-size: 4.8rem;
    --h3-font-size: 3.2rem;
    --h4-font-size: 2.4rem;
    --h5-font-size: 1.6rem;
    --h6-font-size: 1.2rem;
    --header-font-weight: 500;
    --header-margin-top: 2.4rem;
    --header-margin-bottom: 1.2rem;
    --header-hero-font-size: 8rem;
    --header-line-height: 1.2;
    --narrow-content-width: 1000px;
    --header-logo-height: 60px;
    --header-logo-width: 200px;
}

body {
    letter-spacing: .02rem;
    color: var(--body-content-color);
    font-weight: var(--p-font-weight);
}

p {
    font-size: var(--p-font-size);
    margin-bottom: .6rem;
    line-height: 1.6;
}

li,
dl {
    font-size: var(--p-font-size);
}

h1 {
    font-size: var(--h1-font-size);
}

h2 {
    font-size: var(--h2-font-size);
}

h3 {
    font-size: var(--h3-font-size);
}

h4 {
    font-size: var(--h4-font-size);
}

h5 {
    font-size: var(--h5-font-size);
}

h6 {
    font-size: var(--h6-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 + h2,
h1 + h3,
h1 + h4,
h2 + h3,
h2 + h4,
h3 + h4 {
    font-weight: var(--header-font-weight);
    line-height: var(--header-line-height);
    color: var(--body-heading-color);
    top: 0;
    margin-top: 0;
    margin-bottom: var(--header-margin-bottom);
    text-transform: none;

    &.hero {
        font-size: var(--header-hero-font-size);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &:not(:first-child) { margin-top: var(--header-margin-top); }
}
