.cms-featured-cats-block {
    h2 {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 3.2rem;
        letter-spacing: -0.02em;
        font-weight: 600;
        padding-bottom: 2rem;
    }

    &__cat {
        display: block;
        height: 375px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0; right: 0;
            top: 0; bottom: 0;
            background: rgba(0, 0, 0, 0);
            z-index: 10;
        }

        @include desktop {
            &--vertical {
                height: 500px;
            }
        }
    }

    &__cat-content {
        display: block;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include desktop {
            &--left {
                    border-bottom-left-radius: 3rem;
            }

            &--right {
                border-bottom-right-radius: 3rem;
            }
        }

    }

    &__cat-container {
        position: absolute;
        bottom: 5%;
        display: block;
        width: 100%;
        font-size: var(--h5-font-size);
        color: #fff;
        text-align: center;
        z-index: 20;
        font-weight: 700;
        padding: .5rem 0;
        text-transform: uppercase;

        @include desktop {
            font-size: var(--h4-font-size);
        }
    }



}