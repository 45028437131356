/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Menu {
    &-MenuWrapper {
        @include desktop {
            background-color: var(--primary-base-color);
            color: var(--secondary-base-color);
        }
    }

    &-Link {
        @include desktop {
            &:hover,
            &:focus {
                color: var(--secondary-dark-color);
            }

            &_isHovered {
                color: var(--secondary-dark-color);
            }

            .Menu-PageLink & {
                &:hover {
                    color: var(--body-content-color);
                }
            }

        }
    }

    &-Item {
        &:hover {
            @include desktop {
                background-color: var(--primary-dark-color);
            }
        }

        &:first-child {
            @include desktop {
                padding-left: 1.7rem;
            }
        }
    }

    &-SubItemWrapper {
        & > .Menu-Link {
            @include desktop {
                color: var(--body-content-color);
                font-size: 1.6rem;
            }
        }
    }

    &-SubLevelDesktop {
        .Menu-Link {
            @include desktop {
                color: var(--body-content-color);
                font-size: 1.3rem;
                margin-top: 10px;
                margin-left: .5rem;
            }

            &:first-child {
                @include desktop {
                    margin-top: 20px
                }
            }
        };
    }

    &-SubCategories {
        @include desktop {
            padding-top: 30px;
        }
    }

    &-Promotion {
        @include desktop {
            color: var(--body-content-color);
            justify-content: center;
            display: none!important;
        }

    }

    &-PageLink {
        @include desktop {
            margin-top: 0!important;
        }

        &:last-child {
            @include desktop {
                margin-right: 0;
            }
        }
    }

    &-Social {
        @include desktop {
            display: none;
        }
    }

}