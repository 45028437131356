/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --price-color: #{$black};
    --price-with-discount-color: #{$black};
}

.CategoryPage, .slick-slide {
    
    .ProductPrice {
        color: #ffffff;
        
        &_hasDiscount {
            color: #ffffff;
            background-color: var(--primary-base-color);
            padding: 1rem 0;
            text-align: center;
        }
    }

    .ProductPrice-HighPrice {
        color: var(--primary-light-color);
    }
}