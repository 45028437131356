/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-background: #c7f0ff;
    --breadcrumbs-active-color: #0f4c81;
    --breadcrumbs-color: #0b445a;
}

.Breadcrumbs {
    background-color: var(--breadcrumbs-background);

    $crumb-padding: 20px;
    $arrow-size: 4px;

    @include before-desktop {
        --breadcrumbs-background: #fff;

        display: none;
    }

    &-List {
        list-style: none;
        padding: 10px 0;
    }
}
